body {
    margin: 0px;
    padding: 0px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 14px;
}

.content-box {
    flex: 1 1 355px;
}



.main-body {
    margin: 0px;
    padding: 20px 0 20px 0;
    color: rgb(120, 63, 142);
    font-family: Helvetica, Arial, sans-serif;
    font-weight:  normal;
    text-align: center;
}

.sub-heading {
    font-size: large;
    padding-top: 0;
}

.instruction-box {
    display: inline-block;
    margin-top:25px;
    width:100%;
    text-align: center;
}
.inner-instruction-box {
    text-align: left;
    color:#666666;
    font-size: 14px;
    max-width:400px;
    width: calc(100% - 40px);
    display: inline-block;
    padding: 20px;
}

.inner-instruction-box h1 {
    font-size: 16px;
    text-align: center;
}

.too-skinny {
    display: none;
}

ol {
    text-align: left;
    padding-left: 20px;
    margin: 5px 0 5px 0;
}

li {
    padding: 4px;
}

.help-button {
    background-color: #783F8E;
    color: #FFFFFF;
    border-radius: 10px;
    padding: 3px 15px 3px 15px;
    text-decoration: none;
    opacity: 0.8;
    border: 1px solid #783F8E;
}

.help-button:hover {
    opacity: 1;
}



.setup-loader {
display: inline-block;
}

.setup-loader-inner{
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
    margin: 8px;
    border-radius: 50%;
    border: 4px solid #783F8E;
    border-color: #783F8E transparent #783F8E transparent;
    animation: setup-loader 1.5s linear infinite;
}

.wifiSymbol {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    color: #783F8E;
    font-size: 30px;
    margin: 0 30px 10px 20px;
    animation: pulsate 2.0s linear infinite;
}

.show-on-phone {
    display: none;
}


@keyframes setup-loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.pulsate {
    animation: pulsate 2s linear infinite;
}

@keyframes pulsate {
    0% { 
        opacity: 0.5;
    }
    50% { 
        opacity: 1.0;
    }
    100% { 
        opacity: 0.5;
    }
}

  /* Viewing on a phone */
@media (max-width: 480px) {
    .too-skinny {
        display: block;
        background-color: orange;
        padding: 10px;
    }

    .show-on-phone {
        display: block;
    }
  }