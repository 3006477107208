button {
    border: 0px;
    background: transparent;
    cursor:pointer;
}

  .promo {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    color:#666666;
  }

  .promo p {
    font-size: 12px;
  }
  
  .promo .tag-line {
    font-size: 14px;
  }

  .slidecontainer {
    width: 100%; /* Width of the outside container */
    position: absolute;
    bottom: 3px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .delayLoaderWrapper {
    position: absolute;
    top: calc(50% - 100px);
    left:calc(50% - 100px);
  }
  
  /* The slider itself */
  .slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: -moz-calc(100% - 60px);
    width: -webkit-calc(100% - 60px);
    width: calc(100% - 60px);
    height:5px;
    margin-top:14px;
    background: #666666; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 1; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    margin-left:10px;
    margin-right:10px;
  }

    .viewPanel .slider-small {
        top: 10px;
    }

    .viewPanel-flipped .slider-small {
        top: 60px;
    }

  .slider-small .slider {
    -webkit-appearance: slider-vertical;
    writing-mode: vertical-lr;
    direction: rtl;
    width: 4px;
    height: 175px;
    background: #66666666; /* Grey background */
  }
  
  /* Mouse-over effects */
  .slider:hover {
    opacity: 0.7; /* Fully shown on mouse-over */
  }

  .playWrapper {
      padding-top: 8px;
  }
  
  /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 35px; /* Set a specific slider handle width */
    height: 35px; /* Slider handle height */
    background: #333333; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 18px;
  }
  
  .slider::-moz-range-thumb {
    width: 35px; /* Set a specific slider handle width */
    height: 35px; /* Slider handle height */
    background:  #333333; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 18px;
  }

  .slider-small .slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 20px; /* Set a specific slider handle width */
    height: 15px; /* Slider handle height */
    background: #666666; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 10px;
  }
  
  .slider-small .slider::-moz-range-thumb {
    width: 20px; /* Set a specific slider handle width */
    height: 15px; /* Slider handle height */
    background:  #666666; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 10px;
  }
  
  .imagePanelControl {
    margin-left:auto;
    margin-right: auto;
    opacity: 1;
  }
  
  
  .imagePanelControl:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  
  /* SPINNER WHEN WAITING FOR DELAY TO LOAD */
  .delay-loader {
    display: inline-block;
    width: 50px;
    height: 50px;
  }
  .delay-loader:after {
    content: " ";
    display: block;
    width: 40px;
    height: 40px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #666;
    border-color: #666 transparent #666 transparent;
    animation: delay-loader 1.2s linear infinite;
  }
  @keyframes delay-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.call-to-action {
    float:  right;
    background-color: #FFFFFF;
    color: #783F8E;
    padding:7px 20px 7px 20px;
    margin-right:30px;
    border-radius: 15px;
    text-decoration: none;
}

.call-to-action:hover {
    opacity: 0.8;
}

.premium-button {
    background-color: #783F8E;
    color: #FFFFFF;
    padding:7px 14px 7px 14px;
    margin-left:30px;
    border-radius: 15px;
    text-decoration: none;
    margin: -7px 30px;
    font-size:14px;
    font-weight: bold;
}

.premium-button svg {
    color: #FFFFFF
}

.premium-button:hover {
    opacity: 0.8;
}



.menuPanel {
    position: relative;
    width: 50%;
}

.viewPanel {
    position: relative;
    width: 100%;
}

.show-on-landscape-only {
    display: none;
}

.show-on-landscape-only-flipped {
    display: inline-block;
}

.show-on-portrait-only {
    display: inline-block;
}

.show-on-portrait-only-flipped {
    display: none;
}

.menuIcon {
    font-size: 32px;
}

.controlPanel {
    min-height: 60px;
}

.logout-button {
    font-size:20px;
    color: white;
    float: right;
    margin-top: -5px;
    margin-right: 10px;
}

.logout-button p {
    font-size:10px;
    padding: 0px;
    margin: 0px;
}

.heading{
    color: #FFFFFF;
    margin: 0px;
    padding: 10px 0 10px 8px;
    background-color: rgb(120, 63, 142);
    font-size: 13px;
}

.heading div{
    padding:6px 0 3px 2%;
}

.logo {
    height: 25px;
    float: left;
}

.ReactModal__Content{
  width: 410px;
  max-width: 90%;
  height: 540px;
}


/* Landscape mode */
@media screen and (min-aspect-ratio: 10/10) {
    .viewPanel{
        width: 50%;
    }

    .show-on-landscape-only {
        display: inline-block;
    }

    .show-on-portrait-only {
        display: none;
    }

    .show-on-portrait-only-flipped {
        display: inline-block;
    }

    .viewPanel-flipped {
        width: 100%;
    }

    .show-on-landscape-only-flipped {
        display: none;
    }
}

/* Landscape mode and small screen */
@media screen and (min-aspect-ratio: 10/10) and (max-height:400px) {
    .menuIcon {
        font-size: 20px;
        color: #333333;
        padding: 0px;
    }
    
    .controlPanel {
        min-height: 45px;
    }
    
    .heading{
        color: #FFFFFF;
        margin: 0px;
        padding: 3px 0 3px 5px;
        background-color: rgb(120, 63, 142);
        font-size: 12px;
    }
    
    .heading div{
        padding:6px 0 3px 2%;
    }
    
    .logo {
        height: 18px;
        float: left;
    }

    .logout-button {
        font-size:12px;
        color: white;
        float: right;
        margin-top: -2px;
        margin-right: 10px;
    }

    .logout-button p {
        font-size:10px;
        padding: 0px;
        margin: 0px;
    }

    .slider {
        margin-top: 8px;
        margin-left:0px;
    }

    .ReactModal__Content{
      width: 90%;
      height: 90%;
    }
}

/* Promo's need to shrink at 550px */
@media screen and (max-height:550px) {
    .promo h2 {
        font-size:14px;
      }
      
      .promo p {
        font-size: 10px;
        margin: 5px 0;
      }

      .promo .tag-line {
        font-size: 12px;
      }

      .premium-button{
          font-size:10px;
      }
      .compare-table th, td {
        font-size: 10px;
      }
}

.compare-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 90%;
  border: 1px solid #ddd;
  background-color:#FFFFFF;
  display: inline-table;
}

/* Style table headers and table data */
.compare-table th, td {
  text-align: center;
  padding: 8px;
  font-size: 12px;
}

.compare-table th:first-child, td:first-child {
  text-align: left;
}

/* Zebra-striped table rows */
.compare-table tr:nth-child(even) {
  background-color: #f2f2f2
}

.compare-table .fa-check {
  color: green;
  font-size: 18px;
}

.compare-table .fa-minus-circle {
  color: red;
  font-size: 18px;
}